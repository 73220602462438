import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Color } from "../components/designToken"
import Footer from "../components/footer"

const siteWidth = 840
export const Variables = {
  regularLatinScale: 1.16,
  boldLatinScale: 1.17,
  siteWidth: `${siteWidth}px`,
  breakPoint: {
    sp: "max-width: 767px",
    tablet: `max-width: ${siteWidth - 1}px`,
    pc: `min-width: ${siteWidth}px`,
  },
}

const RebrandingPage: React.FC = () => {
  const assets = useStaticQuery(graphql`
    fragment FluidMainSectionImage on File {
      childImageSharp {
        fluid(maxWidth: 840) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    query {
      renewal: file(relativePath: { eq: "renewal.png" }) {
        ...FluidMainSectionImage
      }
      oldLogo: file(relativePath: { eq: "old.png" }) {
        ...FluidMainSectionImage
      }
      newLogo: file(relativePath: { eq: "new.png" }) {
        ...FluidMainSectionImage
      }
      usecases: file(relativePath: { eq: "usecases.jpg" }) {
        ...FluidMainSectionImage
      }
      prototyping: file(relativePath: { eq: "prototyping.jpg" }) {
        ...FluidMainSectionImage
      }
      application: file(relativePath: { eq: "application.png" }) {
        ...FluidMainSectionImage
      }
      applicationSecond: file(relativePath: { eq: "application2.png" }) {
        ...FluidMainSectionImage
      }
    }
  `)
  return (
    <Layout>
      <style jsx>{`
        .hero {
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${Color.white};
          width: 100%;
          padding: 50px 15px;
        }
        .hero video {
          max-width: ${Variables.siteWidth};
        }
        @media (${Variables.breakPoint.tablet}) {
          .hero video {
            max-width: 100%;
          }
        }

        .main {
          background: ${Color.defaultBg};
        }
        .articleTitle {
          margin-bottom: 40px;
        }
        .articleTitle h1 {
          font-size: 3.6rem;
          line-height: 1.5;
        }
        .articleTitle h1 span {
          font-size: ${3.6 * Variables.boldLatinScale}rem;
          margin-right: 1px;
        }
        .subText {
          font-size: 1.8rem;
          line-height: 1;
          letter-spacing: 0.02em;
          color: ${Color.subText};
        }
        @media (${Variables.breakPoint.sp}) {
          .articleTitle {
            margin-bottom: 0;
          }
          .articleTitle h1 {
            font-size: 2.6rem;
            line-height: 1.35;
          }
          .articleTitle h1 span {
            font-size: ${2.6 * Variables.boldLatinScale}rem;
          }
          .subText {
            font-size: 1.4rem;
            margin-top: 5px;
          }
        }

        article {
          word-break: break-word;
          max-width: ${Variables.siteWidth};
          margin: 0 auto;
          font-size: 1.8rem;
          line-height: 2;
          padding: 60px 0 20px;
        }
        article section {
          max-width: 660px;
          margin: 0 auto;
        }
        article p {
          margin: 36px 0;
        }
        p span {
          font-size: ${1.8 * Variables.regularLatinScale}rem;
          line-height: 1.3;
          letter-spacing: 0.02em;
          margin: 0 1px;
        }
        h2 {
          font-size: 2.6rem;
          line-height: 1.75;
          margin: 50px 0 -18px;
        }
        h2 span {
          font-size: ${2.6 * Variables.boldLatinScale}rem;
          margin-right: 1px;
        }
        hr {
          color: #e2e2e2;
          border: 0 none;
          height: 1px;
          background-color: #e2e2e2;
          margin-top: 60px;
        }
        .relLink a {
          margin-left: 8px;
        }
        @media (${Variables.breakPoint.sp}) {
          article {
            padding: 30px 0 30px;
            font-size: 1.6rem;
            line-height: 1.9;
          }
          article section {
            padding: 0 15px;
          }
          article p {
            margin: 30px 0;
          }
          p span {
            font-size: ${1.6 * Variables.regularLatinScale}rem;
          }
          h2 {
            font-size: 2rem;
            margin: 36px 0 -20px;
          }
          h2 span {
            font-size: ${2 * Variables.boldLatinScale}rem;
          }
          .figure {
            width: 100%;
          }
        }

        blockquote {
          background: #f5f5f5;
          font-size: ${1.7}rem;
          padding: 25px 36px;
        }
        blockquote span {
          font-size: ${1.7 * Variables.regularLatinScale}rem;
        }
        a {
          color: ${Color.defaultText};
          text-decoration: none;
          border-bottom: 1px solid #d2d1d0;
        }
        a:hover {
          color: ${Color.subText};
        }
        @media (${Variables.breakPoint.sp}) {
          blockquote {
            font-size: ${1.4}rem;
            padding: 25px 36px;
          }
          blockquote span {
            font-size: ${1.5 * Variables.regularLatinScale}rem;
          }
        }
      `}</style>
      <section className="hero">
        <video
          src={require("../images/motion.mp4")}
          poster={require("../images/poster.png")}
          autoPlay
          playsInline
          loop
          muted
          preload="auto"
        ></video>
      </section>
      <section className="main">
        <article>
          <section>
            <div className="articleTitle">
              <h1>
                <span>Refcome</span>のロゴが新しくなりました
              </h1>
              <div className="subText">2020/2/5 Refcome, Inc.</div>
            </div>
            <p>
              このたび、株式会社リフカムはコーポレート及びサービスロゴをリニューアルいたしました。日々ご利用いただいているユーザーの皆さまに向けて、リニューアルに至った理由やその想いをお伝えします。
            </p>
          </section>
          <Img
            fluid={assets.renewal.childImageSharp.fluid}
            className="figure"
          />
          <section>
            <h2>
              <span>Refcome</span>のこれまで
            </h2>
            <p>
              <span>2014</span>年<span>1</span>月に<span>Combinator (</span>
              旧社名<span>) </span>
              を創業し、今年で創業から丸<span>6</span>
              年を迎えました。前事業から方向転換し、<span>Refcome</span>
              事業をスタートしたのが<span>2016</span>年<span>7</span>
              月のこと。当時の社員は代表の清水ひとり。そんなときに、クラウドソーシングサービスを活用して生まれたのが、これまでのロゴでした。
            </p>
          </section>
          <Img
            fluid={assets.oldLogo.childImageSharp.fluid}
            className="figure"
          />
          <section>
            <p>
              その後<span>3</span>
              年が経過し、いまでは<span>IT</span>
              企業から飲食チェーンまで、幅広い業種で活用いただくサービスへと成長しました。
              <span>Refcome</span>はクラウドサービス<span> (SaaS) </span>
              だけでなく、ポスターやカードといったアイテムを用いて、オフラインにおいても皆さまのリファラル採用活動を支援しています。
            </p>
            <p>
              そのため、クラウドサービスを直接利用する人事の方だけでなく、飲食店であればアルバイトスタッフやそのご友人など、私たちを直接ご存知でない方もロゴを目にする機会が増えました。
            </p>
          </section>
          <Img
            fluid={assets.usecases.childImageSharp.fluid}
            className="figure"
          />
          <section>
            <p>
              これまでのロゴは「リファラル採用」という、採用手法そのものをシンプルに表したものでした。仲間同士が肩を組む姿を、
              <span>referral</span>の頭文字<span> “R” </span>にかけています。
            </p>
            <p>
              これをデザイン品質や機能性といった観点でみると、いくつもの問題がありました。
            </p>
            <blockquote>
              「何を意味しているのか分かりづらい」「カラーがくすんでいて弱々しく感じる」「媒体によってカラーのブレが大きい」「ドイツ語の記号
              <span> (Ä) </span>に見える」などなど
            </blockquote>
            <p>
              このような問題は、リニューアルに至った理由の一部です。なにより決め手になったのは、サービスの進化に合わせて、その顔となるロゴも進化する必要性を感じたことです。
            </p>
            <h2>仲間集めを会社のカルチャーに</h2>
            <p>
              私たちのミッションは「採用を仲間集めに。」です。愛着あふれる会社をつくり、社員全員で、一緒に働きたい人を集めていく。リファラル採用はそのために必要な一部と考えています。
            </p>
            <p>
              今後の<span>Refcome</span>
              は、「リファラル採用手法を簡単に実践できる」サービスから、「仲間集めを会社のカルチャーにする」サービスに進化していきます。昨年秋にリリースした、
              <a href="https://refcome.team/">
                <span>Refcome Teams</span>
              </a>
              はその第一歩です。今後はリファラル採用領域に留まらず、「仲間集め」を実現するためのサービス展開を進めていきます。
            </p>
            <p>
              このような事業展開を踏まえ、デザイン・イノベーション・ファームとして多数の実績を持つ
              <a href="https://takram.com/">
                <span>Takram</span>
              </a>
              とチームを組み、今回のリニューアルプロジェクトが始まりました。オンラインにとどまらず、ユーザーの皆さまと多様な接点を持つ
              <span>Refcome</span>
              だからこそ、誰がどこで見ても一貫して「仲間集めサービスの
              <span>Refcome</span>
              」であると認識してもらえることを目指しました。
            </p>
          </section>
          <Img
            fluid={assets.prototyping.childImageSharp.fluid}
            className="figure"
          />
          <section>
            <p>
              この想いを形にするため多数のプロトタイプを作成し、試行錯誤を重ねながら「採用を仲間集めに。」を表すロゴを決定しました。
            </p>
            <h2>
              <span>Refcome</span>の新しいロゴ
            </h2>
            <p>
              「仲間集め」とは、創業者が灯した火を、みんなの想いとして繋いでいくこと。誰か一人だけの力ではなく、みんなの力で進めること。今回のリニューアルでは、そんな想いを
              <strong>「トーチ」</strong>のモチーフに込めました。
            </p>
          </section>
          <Img
            fluid={assets.newLogo.childImageSharp.fluid}
            className="figure"
          />
          <section>
            <p>
              トーチはオリンピックの聖火リレーをはじめ、自由の女神像や、各地で行われる火祭りなど、洋の東西を問わず古来から様々な場面に登場します。共通した意味合いは、灯した火を絶やさないように、皆で大切にすること。向かうべき方向性や、あるべき姿を照らすこと。私たちの想いを象徴するのにぴったりです。
            </p>
            <p>
              また近年、仕事に対する価値観や働き方は多様化しています。
              <span>Refcome</span>
              のメンバーも、炎のようなアツさだけでなく、優しさや自然体といった多様な個性を持っています。そんな時代性や私たち自身の個性の揺らぎと、炎の揺らぎとを重ねあわせ、コーポレートカラーのオレンジをベースにしたグラデーションで表現しました。
            </p>
          </section>
          <Img
            fluid={assets.application.childImageSharp.fluid}
            style={{ marginBottom: 20 }}
            className="figure"
          />
          <Img
            fluid={assets.applicationSecond.childImageSharp.fluid}
            className="figure"
          />
          <section>
            <p>
              ロゴに関するお話が中心となりましたが、本プロジェクトでは、
              <span>Refcome</span>をどのように見せていくのか
              <span> (Visual Identity) </span>
              の定義も行いました。今後数ヶ月をかけて、サービスはもちろん、ポスターやガイドブックなど、
              <span>Refcome</span>
              に関するあらゆる制作物を、今回定めた方向性に沿って変更していく予定です。
            </p>
            <p>
              それでは新しくなったロゴとともに、今後の<span>Refcome</span>
              にご期待ください！
            </p>
            <p>Refcome Design Team</p>
          </section>
          <hr />
          <section className="relLink">
            <p>
              制作秘話はこちら:
              <span>
                <a
                  href="https://note.com/dex1t/n/n1ca4296c6987"
                  target="_blank"
                >
                  📝note
                </a>
              </span>
              <a
                href="https://cast.takram.com/podcast/refcome01"
                target="_blank"
              >
                <span>🎧Podcast</span>
              </a>
            </p>
          </section>
        </article>
      </section>
      <Footer />
    </Layout>
  )
}
export default RebrandingPage
