import * as React from "react"
import { Color } from "./designToken"
import { Variables } from "../pages/rebranding"

const Footer: React.FC = () => {
  return (
    <>
      <style jsx>{`
        footer {
          background: ${Color.white};
          padding: 30px 15px;
          width: 100%;
        }
        .footerInner {
          max-width: 840px;
          margin: 0 auto;
          width: 100%;
          display: flex;
          align-items: center;
        }
        .leftPane {
          display: flex;
          align-items: center;
        }
        .credit {
          color: ${Color.subText};
          font-size: 1.6rem;
          margin: 2px 15px 0;
        }
        .share {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
        .share li {
          font-weight: bold;
          font-size: 2.2rem;
          margin: 0 10px;
        }
        .share img {
          display: block;
        }
        a:hover img {
          opacity: 0.7;
        }

        @media (${Variables.breakPoint.sp}) {
          footer {
            padding: 0 15px 10px;
          }
          .footerInner {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
          }
          .share {
            padding: 40px 0;
            margin-left: 0;
          }
          .leftPane {
            flex-direction: column;
          }
          .logo {
            width: 120px;
          }
          .credit {
            margin: 7px 0 0;
          }
        }
      `}</style>
      <footer>
        <div className="footerInner">
          <div className="leftPane">
            <a href="https://about.refcome.com/">
              <img
                src={require("../images/refcome.png")}
                width="150px"
                className="logo"
              />
            </a>
            <div className="credit">©2020 Refcome, Inc.</div>
          </div>
          <ul className="share">
            <li>Share on</li>
            <li>
              <a
                href="http://twitter.com/share?url=https%3A%2F%2Frefcome.design%2Frebranding&text=Refcomeのロゴが新しくなりました&related=refcome"
                target="_blank"
              >
                <img src={require("../images/twitter.png")} height="24px" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Frefcome.design%2Frebranding/&text=Refcomeのロゴが新しくなりました"
                target="_blank"
              >
                <img src={require("../images/facebook.png")} height="26px" />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  )
}
export default Footer
